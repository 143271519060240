import store from '@/store'

import { ref, watch } from '@vue/composition-api'

export default function templateList() {
    const headers = [
        { text: '发送时间', value: 'create_at' },
        { text: '分类', value: 'type_name' },
        { text: '手机号', value: 'mobile' },
        { text: '关键内容', value: 'content' },
        { text: '全部内容', value: 'full_content' },
        // { text: '操作', value: 'actions', align: 'center', sortable: false, width: 215 }
    ]
    const param = ref({
        key: '',
        query: '',
        options: { sortBy: ['id'], sortDesc: [false], page: 1, itemsPerPage: 10, state: "1,3,4" }
    })
    const table = ref({
        list: [],
        total: 0,
        snack: {
            show: false,
            msg: ''
        },
        loading: false,
        dialogComplete: {
            show: false,
            ids: []
        }
    })
    const fetchSendRecordList = () => {
        table.value.loading = true
        store.dispatch('content-sms-send-success-list/fetchSendRecordList', param.value).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                console.log("error: ", code, msg);
                table.value.snack = {
                    show: true,
                    msg: `错误码：${code}, ${msg}`
                }
                return
            }
            const { list, total } = res.data.data
            table.value.list = list || []
            table.value.total = total
        }).catch(error => {
            console.log(error)
        }).finally(() => {
            table.value.dialogAddTemplate = {
                show: false,
                data: { id: '' }
            }
            table.value.loading = false
        }
        )
    }
    const completeState = () => {
        table.value.loading = true
        store.dispatch('content-sms-send-success-list/completeState', table.value.dialogCompleteTemplate.ids).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                console.log("error: ", code, msg);
                table.value.snack = {
                    show: true,
                    msg: `错误码：${code}, ${msg}`
                }
                return
            }
            table.value.dialogCompleteTemplate.show = false
            fetchSendRecordList()
        }).catch(error => {
            console.log(error)
        }).finally(table.value.loading = false)
    }

    watch([param], () => {
        fetchSendRecordList()
    }, { deep: true })
    return {
        headers,
        param,
        table,
        fetchSendRecordList,
        completeState,
    }
}
