<template>
    <div id='template-list'>
        <v-dialog v-model="table.dialogComplete.show" max-width="500px">
            <v-card>
                <v-card-title> 确认设为已发？</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" outlined @click="table.dialogComplete = {show: false, ids: []}"> 取消</v-btn>
                    <v-btn color="success" @click=""> 确定</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="table.snack.show" multi-line :light="$vuetify.theme.dark" :timeout="2000">
            {{ table.snack.msg }}
            <template #action="{ attrs }">
                <v-btn color="error" text v-bind="attrs" @click="table.snack.show = false"> 关闭</v-btn>
            </template>
        </v-snackbar>
        <v-card>
            <v-divider class='mt-4'></v-divider>
            <v-card-text class='d-flex align-center flex-wrap pb-0'>
                <v-text-field v-model='param.key' class='user-search me-3 mb-4' dense hide-details outlined
                              placeholder='输入关键字搜索，例如手机号' clearable></v-text-field>
                <v-btn class='mb-4 me-3' color='info' @click="fetchSendRecordList">
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                    <span>搜索</span>
                </v-btn>
                <v-spacer></v-spacer>
<!--                <div class='d-flex align-center flex-wrap'>-->

<!--                    <v-btn class='mb-4 me-3' color='primary' @click="table.dialogCompleteTemplate.show = true">-->
<!--                        <v-icon>{{ icons.mdiPlus }}</v-icon>-->
<!--                        <span>设为已发</span>-->
<!--                    </v-btn>-->
<!--                </div>-->
            </v-card-text>
            <!-- table -->
            <v-data-table :headers='headers' :items='table.list' :loading='table.loading' :options.sync='param.options'
                          :server-items-length='table.total'>
                <!-- actions -->
                <template #[`item.actions`]='{ item }'>
                    <v-row>
                        <v-col>
                            <v-btn small color="info" dark>
                                <v-icon dark left> {{ icons.mdiPlaylistEdit }}</v-icon>
                                重发
                            </v-btn>
                        </v-col>
                    </v-row>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import {mdiPlaylistEdit, mdiDeleteOff,} from '@mdi/js'
import store from '@/store'
import {onUnmounted, ref} from '@vue/composition-api'
import smsStoreModule from '../smsStoreModule'
import sendSuccessList from './contentSmsSendSuccess'

export default {
    components: {}, setup() {
        const CONTENT_SMS_STORE_MODULE_NAME = 'content-sms-send-success-list'
        // Register module
        if (!store.hasModule(CONTENT_SMS_STORE_MODULE_NAME)) store.registerModule(CONTENT_SMS_STORE_MODULE_NAME, smsStoreModule)
        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CONTENT_SMS_STORE_MODULE_NAME)) store.unregisterModule(CONTENT_SMS_STORE_MODULE_NAME)
        })
        const {headers, param, table, fetchSendRecordList, completeState} = sendSuccessList()

        fetchSendRecordList()

        const completeStateDialog = (item) => {
            table.value.dialogDelTemplate = {
                show: true,
                id: item.id
            }
        }

        return {
            headers,
            param,
            table,
            fetchSendRecordList,
            completeStateDialog,
            completeState,
            // icons
            icons: {mdiPlaylistEdit, mdiDeleteOff},
        }
    },
} </script>
<style lang='scss'> @import '@core/preset/preset/apps/user.scss';
</style>
